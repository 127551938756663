.status-count { 
    width: 50%;   
    margin: 1% 0%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Arial, sans-serif';
}
.summaryKey {
    font-size: 16px !important;
    color: #505357;
    background-color: #fff;
    font-family: Open Sans, Verdana, sans-serif;
    font-weight: 400;
}