.mui-date-picker {
    display: flex !important;
    flex-direction: column !important;
    margin-right: 1%;
}

label {
    margin-bottom: 8px;
}

.react-datepicker__input-container input,.dateTimeFilter {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
}

.react-datepicker__input-container input::placeholder {
    color: #6c757d;
}

.react-datepicker__input-container input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
