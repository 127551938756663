.spinner {
  margin: 1rem;
}

.fullPageSpinner .fa-spinner {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.spinner.row {
  align-items: center;
}
